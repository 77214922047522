import "./App.css";

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<h1>
					<span>welcome to</span>Studio Artioli
				</h1>
				<p>
					<span>For more details</span>
					<a className="App-link" href="mailto:contact@studioartioli.co.uk" rel="noopener noreferrer">
						contact@studioartioli.co.uk
					</a>
				</p>
			</header>
		</div>
	);
}

export default App;
